import { createPortal } from "@onecall/portal";
import "./style.css";
import oneclickConfig from "./oneclick-config";
import oneprotectConfig from "./oneprotect-config";
import onecallConfig from "./onecall-config";
import { mergeDeep } from "@onecall/utilities";

let config = {};
switch (import.meta.env.VITE_BRAND_SHORT_NAME) {
  case "One Click":
    config = oneclickConfig;
    break;
  case "One Protect":
    config = oneprotectConfig;
    break
  default:
    config = onecallConfig;
    break;
}

createPortal(
  mergeDeep(
    {
      api: import.meta.env.VITE_API_URL,
      brand: {
        name: import.meta.env.VITE_BRAND_NAME,
        shortName: import.meta.env.VITE_BRAND_SHORT_NAME,
        staticUrl: import.meta.env.VITE_BRAND_STATIC_URL,
        urls: {
          brochure: import.meta.env.VITE_BRAND_URL,
          travel: import.meta.env.VITE_TRAVEL_QUOTE_URL,
          travelTerms: import.meta.env.VITE_TRAVEL_TERMS_URL,
          carQuote: import.meta.env.VITE_CAR_QUOTE_URL,
          vanQuote: import.meta.env.VITE_VAN_QUOTE_URL,
          motorbikeQuote: import.meta.env.VITE_MOTORBIKE_QUOTE_URL,
          homeQuote: import.meta.env.VITE_HOME_QUOTE_URL,
          termsConditions: import.meta.env.VITE_TERMS_CONDITIONS_URL,
          genderPay: import.meta.env.VITE_GENDER_PAY_URL,
          policyDocuments: import.meta.env.VITE_POLICY_DOCUMENTS_URL,
          privacyNotice: import.meta.env.VITE_PRIVACY_POLICY_URL,
          bereavement: import.meta.env.VITE_BEREAVEMENT_URL,
          complaints: import.meta.env.VITE_COMPLAINTS_URL,
          cookiePolicy: import.meta.env.VITE_COOKIE_POLICY_URL,
          modernSlavery: import.meta.env.VITE_MODERN_SLAVERY_URL,
          claims: import.meta.env.VITE_CLAIMS_URL,
          websiteFaqs: import.meta.env.VITE_FAQS_URL,
          tempDriverCover: import.meta.env.VITE_TEMP_DRIVER_COVER_URL,
        },
      },
      bugsnag: {
        apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
      },
      smartlook: {
        apiKey: import.meta.env.VITE_SMARTLOOK_API_KEY,
      },
      endpoints: {
        csrfToken: import.meta.env.VITE_CSRF_TOKEN_URL,
      },
      documents: {
        informationBooklet: {
          description: import.meta.env.VITE_INFORMATION_BOOKLET_NAME,
          link: import.meta.env.VITE_INFORMATION_BOOKLET,
        },
        policyCharges: {
          description: import.meta.env.VITE_CHARGES_BOOKLET_NAME,
          link: import.meta.env.VITE_CHARGES_BOOKLET,
        },
      },
      abiCodes: {
        api: import.meta.env.VITE_ABI_CODES_API_URL,
      },
      addressSearch: {
        api: import.meta.env.VITE_ADDRESS_SEARCH_API_URL,
      },
      vehicleSearch: {
        api: import.meta.env.VITE_VEHICLE_SEARCH_API_URL,
      },
      payments: {
        api: import.meta.env.VITE_PAY_API_URL,
        token: import.meta.env.VITE_PAY_API_TOKEN,
      },
      livechat: {
        provider: import.meta.env.VITE_LIVECHAT_PROVIDER,
      },
    },
    config,
  ),
).mount("#app");
